import Layout from "../components/layout"
import Seo from "../components/seo"
import NavBar from "../components/navBar"
import { Container } from "react-bootstrap"
import GDPRText from "../components/markdown/gdpr"
import React from "react"
import Footer from "../components/footer"

const TermsOfUse = ({ location }) => {
  return (
    <Layout>
      <Seo title="GDPR" />
      <NavBar location={location} />
      <Container className="pb-5">
        <GDPRText />
        <Footer />
      </Container>
    </Layout>
  )
}

export default TermsOfUse
